import "./App.css";
import { Button, Form, Alert, Spinner } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { config } from "./config";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
    const [loading, setLoading] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isPasswordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [checked, setChecked] = useState(false);
    const [errors, setErrors] = useState([]);

    const query = useQuery();

    const token = query.get("token");
    const isDoctor = query.get("doctor");
    const isPasswordReset = query.get("passwordReset");

    useEffect(() => {
        console.log(config);
        if (isDoctor) {
            confirmEmail();
        }
    }, [isDoctor]);

    function handleSubmit(event) {
        event.preventDefault();
        //alert("A name was submitted: " + this.state.value);
        confirmEmail();
    }

    function handlePasswordResetSubmit(event) {
        event.preventDefault();
        //alert("A name was submitted: " + this.state.value);
        resetPassword();
    }

    async function confirmEmail() {
        setLoading(true);
        try {
            const response = await fetch(
                `${config.apiUrl}/v1/auth/activateUserAccount`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        token: query.get("token"),
                        password,
                    }),
                }
            );
            if (response.ok) {
                setIsEmailVerified(true);
            } else {
                setErrors(["Token Expired"]);
            }
        } catch (error) {
            setErrors(["Token Expired"]);
        }
        setLoading(false);
    }

    async function resetPassword() {
        setLoading(true);
        try {
            const response = await fetch(
                `${config.apiUrl}/v1/auth/changeForgottenPassword`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        token: query.get("token"),
                        password,
                    }),
                }
            );
            if (response.ok) {
                setPasswordResetSuccess(true);
            } else {
                setErrors(["Token Expired"]);
            }
        } catch (error) {
            setErrors(["Token Expired"]);
        }
        setLoading(false);
    }

    if (loading) {
        return (
            <div className="App">
                <header className="App-header">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </header>
            </div>
        );
    } else if (!token) {
        return (
            <div className="App">
                <header className="App-header">
                    <div className="email-verified">
                        <img src="logo.png" alt="logo" className="logo" />
                        <h2>v-winapp</h2>
                    </div>
                </header>
            </div>
        );
    } else if (isEmailVerified) {
        return (
            <div className="App">
                <header className="App-header">
                    <div className="email-verified">
                        <img
                            src="email-verification-icon.svg"
                            alt="email verified"
                        />
                        <h2>Email verified</h2>
                        <div
                            style={{
                                display: "flex",
                                width: "500px",
                                marginTop: "20px",
                                justifyContent: "space-around",
                            }}
                        >
                            <a
                                href="https://apps.apple.com/it/app/v-win-caprini-score/id1616032183
"
                            >
                                <img
                                    src="app-store-badge.png"
                                    alt="app-store-badge"
                                    className="store-icon"
                                />
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=it.carlobaratto.capriniriskscore.capriniriskscore">
                                <img
                                    src="google-play-badge.png"
                                    alt="google-play-badge"
                                    className="store-icon"
                                />
                            </a>
                        </div>
                    </div>
                </header>
            </div>
        );
    } else if (isPasswordResetSuccess) {
        return (
            <div className="App">
                <header className="App-header">
                    <div className="password-reset">
                        <img src="password-reset.svg" alt="password reset" />
                        <h2>Password successfully reset</h2>
                    </div>
                </header>
            </div>
        );
    } else if (isPasswordReset) {
        return (
            <div className="App">
                <header className="App-header">
                    <Form
                        onSubmit={handlePasswordResetSubmit}
                        className="d-grid gap-2"
                    >
                        <img src="logo.png" alt="logo" className="logo" />
                        <p className="title">v-winapp</p>
                        <p className="subtitle">
                            Fill in the fields to reset your password
                        </p>
                        <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                        >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="formPasswordCheck"
                        >
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password Confirmation"
                                value={passwordCheck}
                                onChange={(e) =>
                                    setPasswordCheck(e.target.value)
                                }
                            />
                        </Form.Group>

                        {passwordCheck && password !== passwordCheck ? (
                            <Alert key="warning" variant="warning">
                                Passwords not matching
                            </Alert>
                        ) : null}

                        {errors.map((error) => (
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        ))}

                        <Button
                            variant="primary"
                            type="submit"
                            size="lg"
                            disabled={!password || password !== passwordCheck}
                        >
                            Reset your password
                        </Button>
                    </Form>
                </header>
            </div>
        );
    }

    return (
        <div className="App">
            <header className="App-header">
                <Form onSubmit={handleSubmit} className="d-grid gap-2">
                    <img src="logo.png" alt="logo" className="logo" />
                    <p className="title">Welcome to v-winapp</p>
                    <p className="subtitle">
                        Fill in the fields to activate your account
                    </p>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPasswordCheck">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password Confirmation"
                            value={passwordCheck}
                            onChange={(e) => setPasswordCheck(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            label={
                                <div>
                                    Accept
                                    <a
                                        href="https://www.iubenda.com/privacy-policy/10396361"
                                        style={{ marginLeft: "5px" }}
                                    >
                                        Privacy Policy
                                    </a>
                                </div>
                            }
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                        />
                    </Form.Group>
                    {passwordCheck && password !== passwordCheck ? (
                        <Alert key="warning" variant="warning">
                            Passwords not matching
                        </Alert>
                    ) : null}

                    {errors.map((error) => (
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    ))}

                    <Button
                        variant="primary"
                        type="submit"
                        size="lg"
                        disabled={
                            !checked || !password || password !== passwordCheck
                        }
                    >
                        Confirm your account
                    </Button>
                </Form>
            </header>
        </div>
    );
}

export default function QueryParamsExample() {
    return (
        <Router>
            <App />
        </Router>
    );
}
